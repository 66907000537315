import request from './request'

const http = {
  /**
   * @param type 请求方式
   * @param url 请求地址
   * @param params 请求参数
   */
  httpRequest (url, type, params, data, token) {
    const config = {
      method: type,
      url: url,
      token: token,
      params
    }

    if (data) config.data = data
    return request(config)
  }
}
export default http
