<template>
  <div class="box">
    <header>
      <h1>商贸物流结算对账平台</h1>
      <div class="user">
        <i class="el-icon-user-solid" style="color: #fff;font-size: 30px;" @dblclick="setAbc"></i>
        <span style="color: #fff;font-size: 20px; padding:0 10px">欢迎，{{ user }}</span>
        <span class="layout" @click="layout">退出</span>
      </div>
    </header>
    <el-dialog title="设置" :visible.sync="dialogVisible" width="30%">
      <div>
        <label for="">电子账户对账：</label>
        <el-switch v-model="isAbc"></el-switch>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" type="text">取 消</el-button>
        <el-button type="text" @click="saveAbc">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'HeaderSet',
  data () {
    return {
      dialogVisible: false,
      isAbc: false,
      user: window.localStorage.getItem('Phone')
    }
  },
  methods: {
    setAbc () {
      if (window.localStorage.getItem('AbcDuizhang')) {
        window.localStorage.getItem('AbcDuizhang') === 'true' ? this.isAbc = true : this.isAbc = false
      }
      this.dialogVisible = true
    },

    saveAbc () {
      window.localStorage.setItem('AbcDuizhang', this.isAbc)
      this.dialogVisible = false
      this.$emit('set')
    },
    layout () {
      this.$router.push({ path: '/login', query: { accessKey: window.localStorage.getItem('accessKey') } })
      window.localStorage.removeItem('Phone')
      window.localStorage.removeItem('dtoken')
      window.localStorage.removeItem('RefreshToken')
      window.localStorage.removeItem('SubMerPay')
      window.localStorage.removeItem('EBankAccount')
      window.localStorage.removeItem('AbcDuizhang')
    }
  }
}
</script>

<style scoped lang="scss">
* {
  padding: 0;
  margin: 0;
}

body {
  padding: 0;
  margin: 0;
}

header {
  background-color: #545c64;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 0 10px;

  h1 {
    color: #fff;
    padding: 0;
    margin: 0;
    cursor: pointer;
  }

  .user {
    // width: 15%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    .layout {
      color: #fff;
      font-size: 20px;
      cursor: pointer;

      &:hover {
        color: #ffd04b;
      }
    }
  }
}
</style>
