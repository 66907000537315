// import instance from '@/utils/request'

// export const getLogin = (params = {}) => {
//   return instance({
//     method: 'GET',
//     url: '/api/login/login',
//     params
//   })
// }
import http from './index.js'

/**
 *  @param '/api'代表config中index.js中配置的代理
 */
// 结算列表
export function jlist (data) {
  return http.httpRequest('/j-order/search', 'POST', {}, data, true)
}

// 扫码支付列表
export function abcList (data) {
  return http.httpRequest('/abc/merchant-pay-list', 'POST', {}, data, true)
}
// 登录
export function login (data) {
  return http.httpRequest('/third-auth/abc', 'POST', {}, data, false)
}
// 请求验证码
export function sms (data) {
  return http.httpRequest('/third-auth/abc/sms', 'POST', {}, data, false)
}
export function abcduizhang (data) {
  return http.httpRequest('/abc/adapter', 'POST', {}, data, true)
}

// 静态码对帐
export function abccode (data) {
  return http.httpRequest('/abc/static-pay-list', 'POST', {}, data, true)
}
// 验证码
// export function sms1 (params) {
//   return http.httpRequest('/third-auth/abc/sms', 'GET', params, {}, false)
// }
