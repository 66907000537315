import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import moment from 'moment'
Vue.prototype.$moment = moment
Vue.use(ElementUI)
Vue.config.productionTip = false
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
  if (to.meta.title) {
    document.title = to.meta.title
  }
})
router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth) {
    const token = window.localStorage.getItem('dtoken')

    if (token) {
      next()
    } else {
      next({
        path: '/login',
        query: { accessKey: to.query.accessKey }
      })
    }
  } else {
    next()
  }
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
