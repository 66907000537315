// 引入axios
import axios from 'axios'

import router from '@/router/index'
// 创建axios实例
// const tokeninfo = JSON.parse(window.localStorage.getItem('userinfo')).token ? JSON.parse(window.localStorage.getItem('userinfo')).token : ''
const instance = axios.create({
  baseURL: 'https://eshangu.56qqt.net', // 公共地址
  timeout: 5000, // 请求超时时间
  headers: {
    'X-Custom-Header': 'foobar',
    'Access-Control-Allow-Origin': '*'

  }// 请求头，可以不写
})
// 添加请求拦截器
instance.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么

  if (config.token) {
    config.headers.Authorization = window.localStorage.getItem('dtoken') ? 'Bearer ' + window.localStorage.getItem('dtoken') : ''
  }
  return config
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error)
})

// 添加响应拦截器
instance.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  if (response.data.Code === 403 || response.data.Code === 401) {
    window.localStorage.removeItem('Phone')
    window.localStorage.removeItem('dtoken')
    window.localStorage.removeItem('RefreshToken')
    window.localStorage.removeItem('SubMerPay')
    window.localStorage.removeItem('EBankAccount')
    window.localStorage.removeItem('AbcDuizhang')
    router.push('/login')
    // window.location.href = '/#/login'
  }

  return response.data
}, function (error) {
  if (error.response.status === 403 || error.response.status === 401) {
    window.localStorage.removeItem('Phone')
    window.localStorage.removeItem('dtoken')
    window.localStorage.removeItem('RefreshToken')
    window.localStorage.removeItem('SubMerPay')
    window.localStorage.removeItem('EBankAccount')
    window.localStorage.removeItem('AbcDuizhang')
    router.push('/login')
    // window.location.href = '/#/login'
  }
  // 对响应错误做点什么
  // window.localStorage.removeItem('Phone')
  // window.localStorage.removeItem('dtoken')
  // window.localStorage.removeItem('RefreshToken')
  // window.localStorage.removeItem('SubMerPay')
  // window.localStorage.removeItem('EBankAccount')
  // router.push('/login')
  return Promise.reject(error)
})

// 导出axios实例
export default instance
