<template>
 <div class="box">
  <div class="left_menu">
            <el-menu :default-active="active" class="el-menu-vertical-demo" background-color="#545c64" text-color="#fff"
              active-text-color="#ffd04b" :router="true">
              <el-menu-item  index="1" route="/" v-if="EBankAccount" >
                <i class="el-icon-s-home"></i>
                <span slot="title">物流收款</span>
              </el-menu-item>
              <el-menu-item  index="2" route="/shoukuanma" v-if="SubMerPay" >
                <i class="el-icon-s-claim"></i>
                <span slot="title">农行收款码</span>
              </el-menu-item>
              <el-menu-item  index="4" route="/abcCode"  >
                <i class="el-icon-s-goods"></i>
                <span slot="title">电子账户静态码</span>
              </el-menu-item>
              <el-menu-item  index="3" route="/abcduizhang" v-if="AbcDuizhang" >
                <i class="el-icon-s-goods"></i>
                <span slot="title">电子账户</span>
              </el-menu-item>

            </el-menu>
          </div>
 </div>
</template>

<script>
export default {
  name: 'LeftMenu',
  data () {
    return {
      data: [{
        name: '未 到 帐',
        icon: 'el-icon-s-home',
        path: '/',
        index: '1'
      }, {
        name: '已 到 帐',
        icon: 'el-icon-user',
        path: '/received',
        index: '2'
      }, {
        name: '收款记录',
        icon: 'el-icon-s-custom',
        path: '/shoukuanma',
        index: '3'
      }],
      EBankAccount: true,
      SubMerPay: true,
      AbcDuizhang: false
    }
  },
  props: {
    active: {
      type: String,
      default: '1'
    }
  },
  created () {
    window.localStorage.getItem('EBankAccount') === 'true' ? this.EBankAccount = true : this.EBankAccount = false
    window.localStorage.getItem('SubMerPay') === 'true' ? this.SubMerPay = true : this.SubMerPay = false
    this.show()
    console.log(this.AbcDuizhang)
  },
  methods: {
    show () {
      window.localStorage.getItem('AbcDuizhang') === 'true' ? this.AbcDuizhang = true : this.AbcDuizhang = false
    }
  }
}
</script>

<style  lang="scss">
.box {
    height: 100%;
  }
 .left_menu {
    // height: 80vh;
    height: 100%;

    // width: 6%;
    .el-menu-vertical-demo {
      // height: 80vh;
      height: 100%;
      .el-menu-item {
        // display: flex;
        // align-items: center;
        // justify-content: flex-start;
        text-align: left;
        padding-left: 5px!important;
        font-size: 16px;
        .el-icon-menu {
          font-size: 25px;
        }
      }
    }

  }
</style>
