<template>
  <div class="home">
    <el-container>
      <el-header>
        <HeaderSet @set="setPage"/>
      </el-header>
      <el-container>
        <el-aside width="180px">
          <LeftMenu :active="'1'" ref="menu"></LeftMenu>
        </el-aside>
        <el-main>
          <div class="right_content">
            <div class="setting">
              <div class="block">
                <span class="demonstration">开始日期：</span>
                <el-date-picker v-model="value1" align="right" type="date" placeholder="选择日期"
                  :picker-options="pickerOptions">
                </el-date-picker>
              </div>
              <div class="block">
                <span class="demonstration">结束日期：</span>
                <el-date-picker v-model="value2" align="right" type="date" placeholder="选择日期"
                  :picker-options="pickerOptions">
                </el-date-picker>
              </div>
              <div class="block">
                <span>到账状态：</span>
                <el-select v-model="valuetype" placeholder="请选择到账状态">
                  <el-option v-for="item in options" :key="item.value" :label="item.name" :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <div class="block">
                <el-button type="primary" icon="el-icon-search" @click="search">查询</el-button>
                <el-button type="info" icon="el-icon-refresh" @click="search">刷新</el-button>
                <el-button type="warning" icon="el-icon-download" @click="downfile">下载</el-button>
              </div>
            </div>
            <div class="table_title">
             <span v-for="(item,index) in Summary" :key="index" v-show= "item.Amount!==0">{{ item.TransactionName }}:{{ item.Amount }}元</span>
            </div>
            <div class="table">
              <el-table stripe :data="tableData" border :height="height" id="table">
                <el-table-column prop="OrderNo" align="center" label="订单编号">
                </el-table-column>
                <el-table-column prop="OrderInfo" label="订单描述" align="center">
                </el-table-column>
                <el-table-column   align="center" label="订单日期">
                  <template slot-scope="scope">
                    <span>{{ changedate(scope.row.OrderDate) }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="Type" align="center" label="业务类型">
                </el-table-column>
                <el-table-column align="center" label="到账状态">
                  <template slot-scope="scope">
                    <span v-if="scope.row.TradeStatus === '成功'" style="color: #11b43c;">已到账</span>
                    <span v-else style="color: #df0707;">未到账</span>
                  </template>
                </el-table-column>
                <el-table-column align="right" label="金额" header-align="center" width="120">
                  <template slot-scope="scope">
                    <span v-if="scope.row.TradeStatus === '成功'" style="color: #11b43c;">{{ scope.row.Amount }}</span>
                    <span v-else style="color: #df0707;">{{ scope.row.Amount }}</span>

                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </el-main>
      </el-container>
    </el-container>

  </div>
</template>
<script>
import HeaderSet from '@/components/HeaderSet'
import LeftMenu from '@/components/LeftMenu.vue'
import { jlist } from '@/utils/api'
// import FileSaver from 'file-saver'
// import * as XLSX from 'xlsx'
// @ is an alias to /src
export default {
  name: 'HomeView',
  components: {
    HeaderSet,
    LeftMenu
  },
  data () {
    return {
      value1: Date.now() - 3600 * 24 * 60 * 1000,
      value2: Date.now(),
      valuetype: 0,

      options: [{
        name: '全部',
        value: 0
      },
      {
        name: '未到账',
        value: 1
      },
      {
        name: '已到帐',
        value: 2
      }],
      tableData: [],
      Summary: [],
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now()
        },
        shortcuts: [{
          text: '今天',
          onClick (picker) {
            picker.$emit('pick', new Date())
          }
        }, {
          text: '昨天',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24)
            picker.$emit('pick', date)
          }
        }, {
          text: '一周前',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', date)
          }
        }]
      }

    }
  },
  computed: {
    height () {
      return document.documentElement.clientHeight - 220
    }
  },
  created () {
    this.search()
  },

  destroyed () {
    // window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
  },

  methods: {
    changedate (date) {
      return this.$moment(date).format('YYYY-MM-DD')
    },
    setPage () {
      this.$refs.menu.show()
    },
    beforeunloadHandler (e) {
      window.localStorage.removeItem('Phone')
      window.localStorage.removeItem('dtoken')
      window.localStorage.removeItem('RefreshToken')
      window.localStorage.removeItem('SubMerPay')
      window.localStorage.removeItem('EBankAccount')
      window.localStorage.removeItem('AbcDuizhang')
    },
    search () {
      const date1 = this.$moment(this.value1).format('YYYY-MM-DD')
      const date2 = this.$moment(this.value2).format('YYYY-MM-DD')
      jlist({
        startDt: date1,
        endDt: date2,
        summary: false,
        transactionType: [
          'TRANSPORT2FEE', // 到付车费
          'TRANSPORT3FEE', // 回付车费
          'GOODSPRICE', // 代收款
          'DF', // 垫付
          'IFOFEE', // 信息费
          'REFUNDFEE'// 返款费
        ],
        payStatus: this.valuetype
      }).then(res => {
        console.log(res)
        if (res.Code === 200) {
          this.tableData = res.Data.List
          this.allmount()
        } else {
          this.$message.error(res.Msg)
        }
      })
    },
    downfile () {
      const now = new Date()
      const year = now.getFullYear()
      const month = ('0' + (now.getMonth() + 1)).slice(-2)
      const day = ('0' + now.getDate()).slice(-2)
      const formattedTime = `${year}-${month}-${day}` // 获取当前时间，通过时间区分后续下载的文件，看个人意愿自定义添加标题信息
      // const xlsxParam = { raw: true }// 导出的内容只做解析，不进行格式转换，这样就可以解决时间格式乱码的问题
      // const fix = document.querySelector('.el-table__fixed-right') // 判断是否有固定列,有则先移除,导出完成后再添加回去,否则会导致导出的表格错乱
      // let exportTable
      // if (fix) {
      //   exportTable = XLSX.utils.table_to_book(document.querySelector('#table').removeChild(fix), xlsxParam) // 有固定列的时候，先移除固定列，导出完成后再添加回去
      //   document.querySelector('#table').appendChild(fix) // 有固定列的时候，导出完成后再添加回去
      // } else {
      //   exportTable = XLSX.utils.table_to_book(document.querySelector('#table')) // 没有固定列的时候，直接导出
      // }
      // const exportTableOut = XLSX.write(exportTable, { bookType: 'xlsx', bookSST: true, type: 'array' }) // 导出的数据类型
      // try {
      //   FileSaver.saveAs(
      //     new Blob([exportTableOut], {
      //       type: 'application/octet-stream'
      //     }),
      //     `物流收款对帐-${formattedTime}.xlsx`) // 导出的文件名，可自定义，使用模板字符串语法``包裹,可以区分每次下载的文件
      // } catch (e) {
      //   if (typeof console !== 'undefined') {
      //     console.log(e, exportTableOut)
      //   }
      // }
      // return exportTableOut
      import('@/utils/ExportExcel').then(excel => {
        const headers = {
          订单编号: 'OrderNo',
          订单描述: 'OrderInfo',
          订单日期: 'OrderDate',
          业务类型: 'Type',
          到账状态: 'tradeStatus',
          金额: 'amount'
        }
        const data = this.tableData
        data.forEach(item => {
          item.TradeStatus === '成功' ? item.tradeStatus = '已到账' : item.tradeStatus = '未到账'
          item.amount = Number(item.Amount)
          // item.OrderDate = this.$moment(item.OrderDate).format('YYYY-MM-DD')
        })
        const finalData = excel.formatJson(headers, data)
        excel.export_json_to_excel({
          header: Object.keys(headers), // 表头 必填
          data: finalData, // 具体数据 必填
          filename: `物流收款对帐-${formattedTime}`, // 非必填
          autoWidth: true, // 非必填
          bookType: 'xlsx' // 非必填
        })
      })
    },
    allmount () {
      const date1 = this.$moment(this.value1).format('YYYY-MM-DD')
      const date2 = this.$moment(this.value2).format('YYYY-MM-DD')
      jlist({
        startDt: date1,
        endDt: date2,
        summary: true,
        transactionType: [
          'TRANSPORT2FEE', // 到付车费
          'TRANSPORT3FEE', // 回付车费
          'GOODSPRICE', // 代收款
          'DF', // 垫付
          'IFOFEE', // 信息费
          'REFUNDFEE'// 返款费
        ],
        payStatus: this.valuetype
      }).then(res => {
        console.log(res)
        if (res.Code === 200) {
          this.Summary = res.Data.Summary
          // this.tableData = res.Data.List
        } else {
          this.$message.error(res.Msg)
        }
      })
    }
  }

}
</script>
<style lang="scss">
body,
html {
  padding: 0;
  margin: 0;
  background-color: #f0f2f5;

}

.home {
  background-color: #f0f2f5;
  display: flex;
  justify-content: space-between;

  height: 100vh;
  .el-header {
    padding: 0;
  }
  .el-table__row{
    font-size: 15px;
    // font-weight: 500;
  }
  .right_content {
    background-color: #fff;
    padding: 15px;
    // height: 95%;
    // overflow: auto;
    .setting {
      display: flex;
      justify-content: space-evenly;

      .block {
        span {
          font-weight: 500;
        }
      }
    }

    .table_title {
      font-size: 20px;
      text-align: left;
      font-weight: 500;
      color: #ce0f0f;
      padding: 10px 0;
     span{
      padding: 0 10px;
     }
    }
  }
}</style>
